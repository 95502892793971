<template>
  <div>

    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <h6>Detalhes da Tarefas</h6>
            <small class="d-block">
              <strong>Cliente:</strong> Nome Cliente
            </small>
            <small class="d-block">
              <strong>Projeto:</strong> {{ taskLocal.projeto.descricao }}
            </small>
            <small class="d-block">
              <strong>Localização:</strong> Localizacao com problema
              {{ taskLocal.localizacao.descricao }}
            </small>
            <small class="d-block mb-1">
              <strong> Checklist: </strong> {{ taskLocal.checklist.descricao }}
            </small>
            <div class="d-block mb-1">
              <strong> Situação: </strong> {{ taskLocal.tag }}
            </div>
            <hr>
            <h6>Descrição da Tarefa:</h6>
            <small class="d-block mb-1">
              {{ taskLocal.tarefa.descricao }}
            </small>

            <hr>
            <div v-if="taskLocal.executado_por">
              <div class="d-flex align-content-center justify-content-between">
                <h6 class="mb-1">
                  Executado por:
                </h6>
                <feather-icon
                  icon="CheckSquareIcon"
                  class="text-primary"
                  size="25"
                />

              </div>

              <div class="mb-1">

                <b-avatar
                  v-if="taskLocal.executado_por.foto !== null"
                  size="35"
                  :src="taskLocal.executado_por.foto"
                  :variant="`light-${resolveAvatarVariant(taskLocal.tag)}`"
                  :text="avatarText(taskLocal.executado_por.nome)"
                />

                <span class="ml-50 d-inline-block align-middle"> {{ taskLocal.executado_por.nome }}</span>
                <div class="mb-1 text-right font-small-2">
                  Em: {{ formatDataHora(taskLocal.executado_por.data_hora) }}
                </div>
              </div>

              <div v-if="taskLocal.aprovado_por">
                <hr>
                <div class="d-flex align-content-center justify-content-between">
                  <h6 class="mb-1 ">
                    Aprovado por:
                  </h6>
                  <feather-icon
                    icon="CheckSquareIcon"
                    class="text-success"
                    size="25"
                  />

                </div>

                <div class="mb-1">

                  <b-avatar
                    v-if="taskLocal.aprovado_por.foto !== null"
                    size="35"
                    :src="taskLocal.aprovado_por.foto"
                    :variant="`light-${resolveAvatarVariant(taskLocal.tag)}`"
                    :text="avatarText(taskLocal.aprovado_por.nome)"
                  />

                  <span class="ml-50 d-inline-block align-middle"> {{ taskLocal.aprovado_por.nome }}</span>
                  <div class="mb-1 text-right font-small-2">
                    Em: {{ formatDataHora(taskLocal.aprovado_por.data_hora) }}
                  </div>
                </div>

              </div>

              <div v-if="taskLocal.reprovado_por">
                <hr>
                <div class="d-flex align-content-center justify-content-between">
                  <h6 class="mb-1 ">
                    Reprovado por:
                  </h6>
                  <feather-icon
                    icon="XCircleIcon"
                    class="text-danger"
                    size="25"
                  />

                </div>

                <div class="mb-1">

                  <b-avatar
                    v-if="taskLocal.reprovado_por.foto !== null"
                    size="35"
                    :src="taskLocal.reprovado_por.foto"
                    :variant="`light-${resolveAvatarVariant(taskLocal.tag)}`"
                    :text="avatarText(taskLocal.reprovado_por.nome)"
                  />

                  <span class="ml-50 d-inline-block align-middle"> {{ taskLocal.reprovado_por.nome }}</span>
                  <div class="mb-1 text-right font-small-2">
                    Em: {{ formatDataHora(taskLocal.reprovado_por.data_hora) }}
                  </div>
                </div>

              </div>
              <hr>
              <b-form-group v-if="taskLocal.tag !== 'Concluída' && $can('comentario', 'atividades' )">

                <h6>Comentário*</h6>
                <quill-editor
                  id="quil-content"
                  v-model="taskLocal.comentario"
                  :options="editorOption"
                  class="border-bottom-0"
                />
                <div
                  id="quill-toolbar"
                  class="d-flex justify-content-end border-top-0"
                />
              </b-form-group>

              <!-- Form Actions -->
              <div class="d-flex justify-content-center mt-2">

                <b-button
                  v-if="taskLocal.tag === 'Em Aprovação' && $can('aprovar', 'atividades')"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mr-2"
                  type="submit"
                  :disabled="naoExisteComentario"
                  @click="$emit('aprovar-task', taskLocal)"
                >
                  Aprovar
                </b-button>
                <b-button
                  v-if="taskLocal.tag === 'Em Aprovação' && $can('reprovar', 'atividades')"

                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  class="mr-2"
                  type="button"
                  :disabled="naoExisteComentario"
                  @click="$emit('reprovar-task', taskLocal)"
                >
                  Reprovar
                </b-button>
                <b-button
                  v-if="taskLocal.tag === 'Reprovada' && $can('refazer', 'atividades') "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="mr-2"
                  type="submit"
                  :disabled="naoExisteComentario"
                  @click="$emit('refazer-task', taskLocal)"
                >
                  Refazer
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Voltar
                </b-button>
              </div>
            </div>
          </b-form>
        </validation-observer>
        <hr>

        <b-container class="px-2">
          <h4 class="pb-2">
            Histórico da Tarefa
          </h4>
          <timeline-task
            :itens="taskLocal.historicos"
            :tag="taskLocal.tag"
          />
        </b-container>

      </template>

    </b-sidebar>
  </div>
</template>

<script>
import {
  BAvatar, BButton, BContainer, BForm, BFormGroup, BSidebar,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from '@validations'
import { avatarText } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
import TimelineTask from '@/views/components/componentesPensou/TimelineTask.vue'
import useTaskHandler from './useTaskHandler'

export default {
  components: {
    TimelineTask,
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BAvatar,

    BContainer,
    // 3rd party packages

    flatPickr,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
    routeAtual: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,

    }
  },
  computed: {
    naoExisteComentario() {
      return this.taskLocal.comentario === null
          || this.taskLocal.comentario === '' || this.taskLocal.comentario === undefined
    },
  },

  setup(props, { emit }) {
    const {
      taskLocal,
      resetTaskLocal,

      // UI
      assigneeOptions,
      onSubmit,
      tagOptions,
      resolveAvatarVariant,
    } = useTaskHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetTaskLocal, props.clearTaskData)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Informe um comentário.',
    }

    return {
      // Add New
      taskLocal,
      onSubmit,
      assigneeOptions,
      tagOptions,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      editorOption,
      resolveAvatarVariant,

      // Filter/Formatter
      avatarText,
    }
  },
  methods: {
    formatDataHora(value) {
      const date = new Date(value)
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',

      }
      return date.toLocaleDateString('pt-BR', options)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
